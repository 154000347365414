import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2023/4D/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2023/4D/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2023/4D/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2023/4D/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly4D2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/4D/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/4D/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/4D/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/4D/4.webp`;
  

  const photos = [
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 4,
    }, 
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },

    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
    },
    {
          src: p2,
          source:p2,
          rows: 1,
          cols: 4,
        },
   
     
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Save Trees
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4D   Date: 7 September 2023 & 8 September 2023
        </Typography>

        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Save Trees, Save The Earth! We are the guardians of nature’s gift.”

<br/><br/>
        </Typography>
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 4D presented their assembly on the topic ‘Save Trees’ on Thursday, 7 September
2023 and Friday, 8 September 2023.
        <br></br>
        The assembly commenced with a thought that inspired everybody to be as useful as a tree, followed by a
brief introduction about uses of trees which are the most precious gift of nature to humanity. A
melodious rendition of Mridangam was presented.
<br></br>
The highlight of the assembly was the Mime show which effectively conveyed the importance of trees to
the audience. Trees reduce the Green House Effect by removing carbon dioxide from the air and purify
it. Trees play an important role in the water cycle, climate changes and protect wild life. Life on earth is
dependent on trees that are cut down to meet various needs of man which in turn disturbs the
ecological balance in the nature.
<br></br>
The Mime Show strongly expressed the effects of cutting down trees. The assembly concluded with a
strong inspirational message to each and every one to be grateful, honour and preserve trees for the
future generations and encouraged to promote tree plantation to enhance the quality of future life.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “TREES ARE VITAL, WITHOUT THEM, LIFE WOULD BE FATAL”.

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4D2023;